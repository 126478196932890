import React from "react";
import Header from "../components/Header";
import Song from "../components/Song";
import Artist from "../components/Artist";
import TheSong from "../components/TheSongs";
import Wallet from "../components/Minting";
import Faq from "../components/Faq";
import Footer from "../components/Footer";

const Mint = () => {
  return (
    <div>
      <Header />
      <Wallet />
      <Song />
      <TheSong />
      <Faq />
      <Artist />
      <Footer />
   
    </div>
  );
};

export default Mint;
