import React from "react";
import styled from "styled-components";
import TheSongs from "../assets/Song.png";
import Lyrics from "../assets/Lyrics.png";

const Container = styled.div`
  background: #23211e;
  padding: 150px 0;
  @media (max-width: 800px) {
    padding: 50px 0;
  }
`;

const SectionWrapper = styled.div`
  max-width: 80vw;
  margin: 0 auto;

  @media (max-width: 800px) {
    width: 80vw;
    display: grid;
    margin-left:10px;
  }
`;

const Left = styled.div`
  width: 40vw;
  display: inline-block;
`;

const Right = styled.div`
  width: 40vw;
  display: inline-block;
`;

const VideoContainer = styled.div`
  border-radius: 10px;
`;

const Title = styled.div`
  font-family: Le;
  font-size: 120px;
  letter-spacing: 10px;
  text-align: left;
  color: #ede2d1;

  @media (max-width: 800px) {
    font-size: 64px;
    letter-spacing: 5px;
  }
`;

const Heading = styled.div`
  font-family: Termina;
  font-size: 20px;
  color: #ede2d1;
  display: inline-block;
  margin-right: 10px;

  @media (max-width: 800px) {
    margin-right:0;
    
  }
`;

export const Description = styled.div`
  font-size: 24px;

  color: #ede2d1;
  line-height: 1.2;
  display: inline-block;
  text-align: left;

  font-family: TimesBold;

  @media (max-width: 800px) {
    font-size: 18px;

  }
`;

const Desktop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Mobile = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 90vw;

  @media (min-width: 800px) {
    display: none;
  }
`;

const TheSong = () => {
  return (
    <div>
      <Container>
        <SectionWrapper>
          <Desktop>
            <Left>
              <VideoContainer>
                <img src={Lyrics} width="100%" />
              </VideoContainer>
            </Left>
            <Right>
              <Title>THE SONG</Title>

              <Description>
                <Heading>"Sweet Talkin’ Melody" </Heading>
                represents the deterioration of an artist’s mental health and
                the withdrawal from their craft when trying to keep up with the
                modern pace of todays music systems. Ie. social media &
                streaming platforms. Although streaming has opened up some
                positive doors, we feel it’s undervalued music through
                algorithmic favoritism, over accessibility, & paying artists too
                little.
                <br />
                <br />
                "I felt like I had given everything I had to get our music out
                there & at the cost of my mental health. I wrung myself dry. I
                lost all will to make music or art and fell into a deep
                depression. After recovering I knew it was absolutely necessary
                to find a better balance of creating & releasing music.
                Something that maintains the joy of creating & feeds into a
                healthy lifestyle. I believe a lot of artists today are feeling
                the same way." -Jeff Laliberte
              </Description>
            </Right>
          </Desktop>
          <Mobile>
            <SectionWrapper>
              <img src={Lyrics} width="150%" className="song" />
              <Title>THE SONG</Title>

              <Description>
                <Heading>"Sweet Talkin’ Melody"</Heading>
                represents the deterioration of an artist’s mental health and
                the withdrawal from their craft when trying to keep up with the
                modern pace of todays music systems. Ie. social media &
                streaming platforms. Although streaming has opened up some
                positive doors, we feel it’s undervalued music through
                algorithmic favoritism, over accessibility, & paying artists too
                little.
                <br />
                <br />
                "I felt like I had given everything I had to get our music out
                there & at the cost of my mental health. I wrung myself dry. I
                lost all will to make music or art and fell into a deep
                depression. After recovering I knew it was absolutely necessary
                to find a better balance of creating & releasing music.
                Something that maintains the joy of creating & feeds into a
                healthy lifestyle. I believe a lot of artists today are feeling
                the same way." -Jeff Laliberte
              </Description>
            </SectionWrapper>
          </Mobile>
        </SectionWrapper>
      </Container>
    </div>
  );
};

export default TheSong;
