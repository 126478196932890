import React from "react";
import styled from "styled-components";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaDiscord, FaLink } from "react-icons/fa";

const Container = styled.div`
  background: #23211e;
  padding-bottom: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
//   max-width: 0vw;
  align-items:center;
  justify-content:center;

  @media (max-width: 800px) {
    width: 100vw;
  }
`;
const SocialContainer = styled.div`
  background: #ede2d1;
  border-radius: 100px;
  margin: 10px;
  padding: 10px 5px;
`;

const Title = styled.div`
  font-family: Termina;
  font-size: 18px;
  color: #23211e;
  margin-right: 10px;
  display: inline-block;
`;

const ImgContainer = styled.div`
  align-items: left;
  display: inline-block;
  color: #23211e;
  margin: 0 10px 0 10px;
`;

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <a href="https://twitter.com/NewBeatFund">
          <SocialContainer>
            <ImgContainer>
              <BsTwitter />
            </ImgContainer>
            <Title>Twitter</Title>
          </SocialContainer>
        </a>
        <a href="https://discord.com/invite/XGpkbVUw">
          <SocialContainer>
            <ImgContainer>
              <FaDiscord />
            </ImgContainer>
            <Title>Discord</Title>
          </SocialContainer>
        </a>

        <a href="https://newbeatfund.com/">
          <SocialContainer>
            <ImgContainer>
              <FaLink />
            </ImgContainer>
            <Title>Website</Title>
          </SocialContainer>
        </a>
        <a href="http://instagram.com/newbeatfund">
          <SocialContainer>
            <ImgContainer>
              <BsInstagram />
            </ImgContainer>
            <Title>Instagram</Title>
          </SocialContainer>
        </a>
      </Wrapper>
    </Container>
  );
};

export default Footer;
