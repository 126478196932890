import React from "react";
import NBFHeader from "../assets/NBFHeader.png";
import NBFLogo from "../assets/NBFLogo.svg";
import Song from "../assets/Sweet.svg";
import styled from "styled-components";

const Container = styled.div`
  background: #23211e;
`;

export const Button = styled.button`
  background: #ede2d1;
  width: 250px;
  padding: 10px 10px;
  color: black;
  border-radius: 40px;
  font-family: Termina;
  border: none;
  font-size:24px;

  @media (max-width: 800px) {
    width: 150px;
    font-size:12px;
  }
`;

const TextWrapper = styled.div`
  padding: 150px 0;

  @media (max-width: 800px) {
    padding: 50px 0;
  }
`;

const Header = () => {
  return (
    <div>
      <Container>
        <img src={NBFHeader} width="100%" />
        <TextWrapper>
          <img src={NBFLogo} width="30%" />
          <img src={Song} width="100%" />
          <a href="#mint">
          <Button >MINT IS LIVE </Button></a>
        </TextWrapper>
      </Container>
    </div>
  );
};

export default Header;
