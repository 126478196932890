import React from "react";
import { Accordion } from "react-bootstrap";
import "./style.css";
import styled from "styled-components";

const Container = styled.div`
  background: #ede2d1;
  padding: 100px;

  @media (max-width: 800px) {
    padding: 50px 20px;
 
  }
`;

const Title2 = styled.div`
  font-family: Le;
  font-size: 120px;
  letter-spacing: 10px;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 80px;
  }
`;

const Title = styled.div`
  font-family: Termina;
  font-size: 20px;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;

const Descriptrion = styled.div`
  font-size: 24px;
  line-height: 1.2;
  font-family: TimesRoman;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px;
  margin-top: 20px;
`;

const SectionWrapper = styled.div`

  //   display: flex;
  margin: 0 auto;
  width: 80vw;
`;

const Faq = () => {
  return (
    <div>
      <Container>
        <SectionWrapper>
          <Title2>F.A.Q</Title2>
          <Accordion defaultActiveKey="0">
            <Wrapper>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Title>What is the supply and mint?</Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Descriptrion>
                    There will be a total of 55 Sweet Talkin’ Melody NFTs
                    available. <br />
                    Mint price is 0.1 ETH.
                  </Descriptrion>
                </Accordion.Body>
              </Accordion.Item>
            </Wrapper>

            <Wrapper>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <Title>When is the launch date?</Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Descriptrion>
                    We are launching on February 08, 2023 at 1pm PST / 4pm EST.
                    NOTE: We will have a 24 hour priority for POAP holders.
                    Official launch details will be shared on our website,
                    Discord, and our Twitter page.
                  </Descriptrion>
                </Accordion.Body>
              </Accordion.Item>
            </Wrapper>
            <Wrapper>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <Title>What is the utility?</Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Descriptrion>
                    Being an owner of this NFT will give access to private
                    discord to interact with us and our community of NFT
                    holders. You will have first access to future NFT art
                    releases. Owners of the Sweet Talking Melody NFT will have
                    governance of the song. The community will decide what
                    happens with the song. Proposals may be made to utilize the
                    song on DSPs or sync and license for example….or to keep it
                    underground and private for the elite collectors. The
                    community will govern the fate of the song. Each NFT holder
                    will be invited to a private event at our studio at Sound
                    City Center in Van Nuys, CA to celebrate and see how and
                    where the song was created. Event details TBA.
                  </Descriptrion>
                </Accordion.Body>
              </Accordion.Item>
            </Wrapper>
            <Wrapper>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <Title>What are NFTs?</Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Descriptrion>
                    NFT stands for “non-fungible token” which are unique digital
                    items that are stored and encrypted on the blockchain that
                    people can buy, own, and trade. NFTs can simply be digital
                    art but can have various benefits. They can be treated like
                    membership cards where holders get exclusive access to
                    things like websites, events, merchandise, and more.
                  </Descriptrion>
                </Accordion.Body>
              </Accordion.Item>
            </Wrapper>
            <Wrapper>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <Title>How do I buy a Sweet Talkin’ Melody NFT?</Title>
                </Accordion.Header>
                <Accordion.Body>
                  <Descriptrion>
                    1. Sign up for Metamask or Coinbase Wallet and download the
                    extension on your internet browser.
                    <br />
                    <br /> 2. Make sure you have enough Ethereum in your wallet
                    to cover the total cost including gas fees. <br />
                    <br />
                    3. On mint day, there will be a Connect button at the top of
                    our website to connect to your wallet.
                    <br />
                    <br />
                    4. Click on the mint button and you will be prompted to sign
                    for your transaction. There will be a fee associated with
                    every transaction related to gas prices. <br />
                    <br />
                    5. Once you have made your purchase, your NFT will appear in
                    your wallet and on Opensea!
                  </Descriptrion>
                </Accordion.Body>
              </Accordion.Item>
            </Wrapper>
          </Accordion>
        </SectionWrapper>
      </Container>
    </div>
  );
};

export default Faq;
