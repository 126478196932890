import { BigNumber, ethers } from "ethers";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
  useAccount,
} from "wagmi";
import { config as getConfig } from "../config/config";
import { Title } from "./Artist";
import { Description } from "./TheSongs";

const Container = styled.div`
  background: #ede2d1;
  z-index: 1;
`;

const MintButton = styled.button`
  background: #23211e;
  color: #ede2d1;
  border-radius: 15px;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }

  width: 320px;
  height: 80px;
  border-radius: 20px;
  font-family: Termina;
  border: none;
  font-size: 36px;

  :hover{
    background:#ede2d1 ;
    color:#23211e ;
    border: 10px solid  #23211e;
  }

  @media (max-width: 800px) {
    width: 150px;
    height: auto;
    padding: 10px 0;
    font-size: 18px;
    margin-top: 30px;
  }
`;

const PopUpContainer = styled.div`
  background: #23211e;
  border-radius: 40px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  height: 400px;
  z-index: 2;
  max-width: 60vw;
  position: relative;
  margin-top: 30px;

  @media (max-width: 800px) {
    max-width: 80vw;
    height: 300px;
    border-radius: 10px;
    padding: 0 20px;
  }
`;

const Text = styled.div`
  margin: 0 auto;
  padding: 0 auto;
  align-items: center;
  text-align: center;
`;

const Title2 = styled(Title)`
  margin: 0 auto;
  align-items: center;
  text-align: center;
  padding-top: 25px;

  @media (max-width: 800px) {
    font-size: 44px;
  }
`;

const Description2 = styled(Title2)`
  font-size: 24px;
  letter-spacing: 0.3px;
  padding-top: 10px;
  padding: 10px 20px 0 20px;
  font-family: Termina;
  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

const Title3 = styled(Description2)`
  color: #23211e;
  letter-spacing: 0.5px;
  padding-top: 0;font-size:18px;
`;

const TwitterContainer = styled.div`
  background: #ede2d1;
  width: 270px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 10px 3px;
`;

const TrxText = styled.div`
color: #23211e;
font-family: Termina;
font-size:18px;
font-decoration:none;
`

const ShareContainer = styled.div``;

const options = {
  headers: {
    validate: process.env.REACT_APP_VALIDATE_TOKEN,
  },
};
const axios = require("axios").default;

const Mint = () => {
  const [numberOfTokens, setNumberOfTokens] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);
  const { address, isConnected } = useAccount();

  const [twitter, setTwitter] = useState(null);
  const [place, setPlace] = useState(null);
  const [leaderboard, setLeaderBoard] = useState([]);
  const [user, getUser] = useState(0);
  const [mint, getMinted] = useState(false);

  const postWallet = async () => {
    try {
      console.log(address);
      const postData = await axios({
        method: "POST",
        url: `${getConfig.apiBaseUrl}/user`,
        headers: getConfig.authOptions.headers,
        data: {
          walletAddress: address,
        },
      });

      console.log(postData);
    } catch (err) {
      console.log(err);
    }
  };

  const postUserInfo = async () => {
    try {
      console.log(twitter);
      console.log(place);
      const updateUserInfo = await axios({
        method: "PATCH",
        url: `${getConfig.apiBaseUrl}/userTwitter`,
        headers: getConfig.authOptions.headers,
        data: {
          walletAddress: address,
          twitter: twitter,
          nationality: place,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    postWallet();
  }, [address]);

  const getUserMint = async () => {
    try {
      const url = `${getConfig.apiBaseUrl}/user/${address}`;
      const { data } = await axios.get(url, getConfig.authOptions);
      let userMintCounter = data.data.mintCount;
      console.log(`user Minted ${userMintCounter}`);
      getUser(userMintCounter);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserMint();
  }, []);

  const checkUserMinted = async () => {
    if ((user = 0)) {
    } else {
      getMinted = true;
    }
  };

  useEffect(() => {
    checkUserMinted();
  }, []);

  const getLeaderboardDetails = async () => {
    try {
      console.log("get LeaderBoard");
      const url = `${getConfig.apiBaseUrl}/leaderboard`;
      const { data } = await axios.get(url, getConfig.authOptions);
      let listOfCards = [];
      data.data.map((item) => {
        const card = renderLeaderboardRow(
          item.walletAddress,
          item.nationality,
          item.twitter,
          item.mintCount
        );
        listOfCards.push(card);
      });
      setLeaderBoard(listOfCards);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLeaderboardDetails();
  }, []);

  const renderLeaderboardRow = (
    walletAddress,
    nationality,
    mintCount,
    twitter
  ) => {
    return (
      <div>
        {walletAddress}
        <hr></hr>
        {nationality}
        <hr></hr>
        {mintCount}
        <hr></hr>
        {twitter}
        <br />
        <br />
      </div>
    );
  };

  const whitelistRead = useContractRead({
    addressOrName: getConfig.contractAddress,
    contractInterface: [
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "checkIfWhitelisted",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "checkIfWhitelisted",
    args: [address],
  });

  // console.log("checkIfWhitelisted: ", whitelistRead);

  const { config } = usePrepareContractWrite({
    addressOrName: getConfig.contractAddress,
    contractInterface: [
      {
        inputs: [
          {
            internalType: "uint256",
            name: "numberOfTokens",
            type: "uint256",
          },
        ],
        name: "mintNBF",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
    functionName: "mintNBF",

    args: [parseInt(numberOfTokens)],
    enabled: Boolean(numberOfTokens),
    overrides: {
      from: address,
      value: whitelistRead?.data
        ? "0"
        : ethers.utils.parseEther(
            `${parseFloat("0.1") * parseFloat(numberOfTokens)}`
          ),
    },
    onSuccess(data) {
      console.log("update mint count");
      mintCountUpdate();
    },
    onError(data) {
      console.log("Some error");
    },
  });
  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const nbfRead = useContractRead({
    addressOrName: getConfig.contractAddress,
    contractInterface: [
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "totalSupply",
  });

  const incrementMintCount = async (walletAddress) => {
    const { data } = await axios({
      method: "PATCH",
      url: `${getConfig.apiBaseUrl}/user`,
      headers: getConfig.authOptions.headers,
      data: {
        walletAddress,
      },
    });
    console.log("PATCH user success walletAddress : ", data);
  };

  const mintCountUpdate = async () => {
    console.log("mint initiate");

    console.log("txn data: ", data);

    console.log("isLoading: ", isLoading);
    console.log("isSuccess: ", isSuccess);

    while (isLoading && !isSuccess) {
      console.log("comfirmed.. waiting for success txn");
    }
    console.log("isLoading: ", isLoading);
    console.log("isSuccess: ", isSuccess);

    if (isSuccess && !isLoading) {
      for (let i = 0; i < numberOfTokens; i++) {
        console.log("address for patch user: ", address);
        await incrementMintCount(address);
      }
    }
  };

  // useEffect(() => {
  //   if (data?.hash) mintCountUpdate();
  // }, [isLoading, isSuccess]);

  // console.log(nbfRead);

  return (
    <div>
      <Container>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            write?.();
          }}
        >
          
          <MintButton disabled={!write || isLoading}>
            {isLoading ? "Minting..." : "Mint"}
          </MintButton>
          {isSuccess && !isLoading ? (
            <TrxText>
              Successfully minted your NFT!
              <div>
                <a
                  target={"_blank"}
                  href={`${getConfig.explorerUrl}/tx/${data?.hash}`}
                  rel="noreferrer"
                  className="trxlink"
                >
                  Etherscan
                </a>
              </div>
            </TrxText>
          ) : isLoading ? (
            <TrxText>
              <a
                target={"_blank"}
                href={`${getConfig.explorerUrl}/tx/${data?.hash}`}
                rel="noreferrer"
                className="trxlink"
              >
                Pending Txn...
              </a>
            </TrxText>
          ) : null}
          <br />
          <label for="numberOfTokens" className="number">
            Number Of Tokens :{" "}
            <input
              id="tokens"
              onChange={(e) => setNumberOfTokens(e.target.value)}
              placeholder="2"
              type="number"
              min={1}
              max={2}
              className="numberinput"
              value={numberOfTokens}
            />
          </label>

          <p className="price">
            {whitelistRead?.data ? (
              <span>You are whitelisted!! :D</span>
            ) : (
              <>
                <span>0.1 ETH / NFT</span> <br />
                <span>Max 2 per wallet</span>
              </>
            )}
          </p>
        </form>
        {/* {leaderboard} */}
        <div className="supply"> Minted : {nbfRead?.data?.toString()}/55</div>

        {isSuccess ? (
          <>
            {/* {user} */}
            <PopUpContainer>
              <Text>
                <Title2>Congratulations 🎉</Title2>
                <Description2>
                  You've collected NBF's first music NFT ever - this is just the
                  begining come along for the ride <br />
                  {/* Please add your details to be added to the leaderboard */}
                </Description2>{" "}
              </Text>
              {/* <div>
                <br />
                <Description>Twitter Handle</Description>
                <br />
                <input
                  id="tokens"
                  onChange={(e) => setTwitter(e.target.value)}
                  placeholder="a_moonboy"
                  value={twitter}
                  className="input"
                />
                <br />
                <Description> Country </Description>
                <br />
                <input
                  type="string"
                  id="country"
                  onChange={(e) => setPlace(e.target.value)}
                  placeholder="USA"
                  value={place}
                  className="input"
                />
                <br />

                <button className="submit" onClick={() => postUserInfo()}>
                  Submit
                </button>
              </div> */}
              <ShareContainer>
                Share On{" "}
                <a
                  class="twitter-share-button"
                  href={`https://twitter.com/intent/tweet?text=I’m officially a music NFT collector. @NewBeatFund released their most rare piece of art to date, only as a music NFT at NFT.newbeatfund.com. Song will not be on DSPs! Only 55 in supply total.`}
                  target={"_blank"}
                  rel="noreferrer"
                  className="link"
                >
                  <TwitterContainer>
                    <Title3>Share on Twitter</Title3>
                  </TwitterContainer>
                </a>
              </ShareContainer>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charset="utf-8"
              ></script>
            </PopUpContainer>
          </>
        ) : (
          <> </>
        )}
      </Container>
    </div>
  );
};

export default Mint;
