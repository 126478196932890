import React from "react";
import styled from "styled-components";
import Band from "../assets/Band.jpeg";

const Container = styled.div`
  background: #23211e;
  padding: 150px 0;

  @media (max-width: 800px) {
    padding: 50px 0;
  }
`;

const SectionWrapper = styled.div`
  width: 80vw;
  display: flex;
  margin: 0 auto;
  @media (max-width: 800px) {
 width:80vw;
 display:grid;
  }
`;

const Left = styled.div`
  width: 40vw;
  display: inline-block;
`;

const Right = styled.div`
  width: 40vw;
  display: inline-block;
`;

const VideoContainer = styled.div``;

export const Title = styled.div`
  font-family: Le;
  font-size: 80px;
  text-align: left;
  color: #ede2d1;
  @media (max-width: 800px) {
    font-size: 64px;
    letter-spacing: 2px;
  }
`;

const Heading = styled.div`
  font-family: Termina;
  font-size: 24px;
  text-align: left;
  color: #ede2d1;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 1.6;
  }
`;

const Description = styled.div`
  font-size: 24px;
  font-family: TimesRoman;
  line-height: 1.2;
  color: #ede2d1;
  text-align: left;
  margin-top: 10px;
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 1.2;
  }
`;

const Desktop = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Mobile = styled.div`
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 800px) {
    display: none;
  }
`;

const Artist = () => {
  return (
    <div>
      <Container>
        <Desktop>
          <SectionWrapper>
            <Left>
              <img src={Band} width="80%" />
            </Left>
            <Right>
              <VideoContainer>
                <Title>THE ARTIST</Title>
                <Heading>NEW BEAT FUND</Heading>
                <Description>
                  California’s New Beat Fund (NBF) are a west coast alternative
                  band comprised of Jeffrey Laliberte, Paul Laliberte, and
                  Shelby Archer. With the trio writing and self-producing each
                  track, their unpredictably unique take on pop music is
                  characteristic in every one of their songs. Following their
                  debut “Sponge Fingerz” and lead single “No Type” the band
                  would secure opening tour slots with Blink 182, AWOLNATION, &
                  Dirty Heads, national radio airplay, worldwide playlists, and
                  major festivals across the US. 2018’s release “Chillanthropy”
                  produced collaborative efforts alongside close friends The
                  Driver Era and DJ duo Two Friends, both of which have earned
                  them critical acclaim and a cult following. Currently writing
                  and producing music for artists under the moniker “The Fund”
                  from their studio at Sound City Center in Van Nuys, CA —
                  they’ve decided 2023 is a perfect time to drop new music as a
                  series of NFT’s.
                </Description>
              </VideoContainer>
            </Right>
          </SectionWrapper>
        </Desktop>
        <Mobile>
          <SectionWrapper>
          <img src={Band} width="90%" />
          <Title>THE ARTIST</Title>
          <Heading>NEW BEAT FUND</Heading>
          <Description>
            California’s New Beat Fund (NBF) are a west coast alternative band
            comprised of Jeffrey Laliberte, Paul Laliberte, and Shelby Archer.
            With the trio writing and self-producing each track, their
            unpredictably unique take on pop music is characteristic in every
            one of their songs. Following their debut “Sponge Fingerz” and lead
            single “No Type” the band would secure opening tour slots with Blink
            182, AWOLNATION, & Dirty Heads, national radio airplay, worldwide
            playlists, and major festivals across the US. 2018’s release
            “Chillanthropy” produced collaborative efforts alongside close
            friends The Driver Era and DJ duo Two Friends, both of which have
            earned them critical acclaim and a cult following. Currently writing
            and producing music for artists under the moniker “The Fund” from
            their studio at Sound City Center in Van Nuys, CA — they’ve decided
            2023 is a perfect time to drop new music as a series of NFT’s.
          </Description>
          </SectionWrapper>
        </Mobile>
      </Container>
    </div>
  );
};

export default Artist;
