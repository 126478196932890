export const config = {
  contractAddress: "0x0Be8C8602F38F13Fc0cB4b8EA5A7f9247d1c2Daf",
  alchemyKey: process.env.REACT_APP_ALCHEMY_KEY,
  explorerUrl: "https://etherscan.io",
  apiBaseUrl: "https://newbeatfund.kraznikunderverse.com",
  authOptions: {
    headers: {
      validate: process.env.REACT_APP_VALIDATE_TOKEN,
    },
  },
};
