import "./App.css";
import Mint from "./pages/mint";


function App() {
  return (
    <div className="App">
      <Mint />
    </div>
  );
}

export default App;
