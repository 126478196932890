import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import {
  defaultChains,
  configureChains,
  createClient,
  WagmiConfig,
} from "wagmi";
import { mainnet } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import { darkTheme } from "@rainbow-me/rainbowkit";
import styled from "styled-components";
import Mint from "./Mint.js";
import { config } from "../config/config";

const Container = styled.div`
  background: #ede2d1;
  padding: 40px 0;
`;

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
  //defaultChains,
  [mainnet],
  [
    alchemyProvider({
      apiKey: config.alchemyKey,
    }),
    // publicProvider(),
  ],
  { targetQuorum: 2, minQuorum: 1 }
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains,
});

// Set up client
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});

const ConnectWallet = styled(ConnectButton)`
  align-items: center;
`;

const WalletWrapper = styled.div`
  margin: 0 auto;
  justify-content: center;
  justify-item: center;
  max-width: 80vw;
  align-items: center;
`;

export default function Wallet() {
  return (
    <Container>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          theme={darkTheme({
            accentColor: "#EDE2D1",
            accentColorForeground: "black",
            borderRadius: "large",
          })}
        >
          <WalletWrapper>
            <ConnectWallet></ConnectWallet>
          </WalletWrapper>

          <Mint />
        </RainbowKitProvider>
      </WagmiConfig>
    </Container>
  );
}
