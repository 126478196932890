import React from "react";
import styled from "styled-components";
import Coin from "../assets/Coin.mp4";

const Container = styled.div`
  background: #ede2d1;
  padding: 150px 0;

  @media (max-width: 800px) {
    padding: 50px 0;
  }
`;

const SectionWrapper = styled.div`
  max-width: 80vw;
  display: flex;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 50px 0;
    width: 80vw;
    display: grid;
    margin: 0 auto;
  }
`;

const Left = styled.div`
  width: 40vw;
  display: inline-block;
`;

const Right = styled.div`
  width: 40vw;
  display: inline-block;
`;

const VideoContainer = styled.div`
  border-radius: 10px;
  margin: o auto;

  @media (max-width: 800px) {
    border: 1px solid black;
  }
`;

const Title = styled.div`
  font-family: Le;
  font-size: 120px;
  letter-spacing: 10px;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 64px;
    letter-spacing: 2px;
  }
`;

const Heading = styled.div`
  font-family: Termina;
  font-size: 20px;
  text-align: left;
  max-width: 900px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 1.6;
  }
`;

export const Description = styled.div`
  font-size: 24px;
  line-height: 1.3;
  text-align: left;
  font-family: TimesRoman;

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 1.2;
  }
`;

const Desktop = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Mobile = styled.div`
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 800px) {
    display: none;
  }
`;

const Song = () => {
  return (
    <div>
      <Container>
        <Desktop>
          <SectionWrapper>
            <Left>
              <Title>THE NFT PROJECT</Title>
              <Heading>
                The NFT movement is a shift in the meaning of how intellectual
                property is utilized both for consumers and artists.
              </Heading>
              <Description>
                NBF has always been forward thinking in the way we present and
                handle our art. Coming from a DIY mindset and growing into
                having label partnerships, we have seen the scope of what it
                means to put our art out in many different formats with and
                without partners. There are now tools out there to do things in
                a fundamentally new way. Web3 direct from artist purchase of a
                unique digital art piece translates to you owning something
                concretely unique and limited.
              </Description>
            </Left>
            <Right>
              <VideoContainer>
                <iframe
                  src="https://giphy.com/embed/C3HjjuzcT21CcBv6mv"
                  width="100%"
                  height="480"
                  frameBorder="0"
                  class="giphy-embed"
                  allowFullScreen
                ></iframe>
              </VideoContainer>
            </Right>
          </SectionWrapper>
        </Desktop>
        <Mobile>
          <SectionWrapper>
            <VideoContainer>
              <iframe
                src="https://giphy.com/embed/C3HjjuzcT21CcBv6mv"
                width="300"
                height="300"
                frameBorder="0"
                class="giphy-embed"
                allowFullScreen
              ></iframe>
            </VideoContainer>

            <Title>THE NFT PROJECT</Title>
            <Heading>
              The NFT movement is a shift in the meaning of how intellectual
              property is utilized both for consumers and artists.
            </Heading>
            <Description>
              NBF has always been forward thinking in the way we present and
              handle our art. Coming from a DIY mindset and growing into having
              label partnerships, we have seen the scope of what it means to put
              our art out in many different formats with and without partners.
              There are now tools out there to do things in a fundamentally new
              way. Web3 direct from artist purchase of a unique digital art
              piece translates to you owning something concretely unique and
              limited.
            </Description>
          </SectionWrapper>
        </Mobile>
      </Container>
    </div>
  );
};

export default Song;
